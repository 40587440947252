/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules//bootstrap-icons/font/bootstrap-icons.css';

@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
  margin: 0px;
  touch-action: manipulation;
}

::-webkit-scrollbar {
  display: none;
}

.green-transparent-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: 2px solid #4caf50;
  color: #4caf50;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
